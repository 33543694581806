import React, { useRef, useState } from "react";
import { Modal, Button, Row, Spin, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const CustomFileUploadModal = ({
  visible,
  onOk,
  id,
  onCancel,
  name,
  props,
  showDownload,
  downloadHandler,
  loading,
}) => {
  const file = useRef();
  const [file_name, set_file_name] = useState();

  const handleFileChange = async (e) => {
    e.preventDefault();
    if(file.current.files) {
        onOk(file.current.files)
    }
    else {
        notification.warning({message: 'No File is Selected'})
    }
  };

  const onChange = e => {
    set_file_name(e.target.value)
  }

  const handleCancle = () => {
    file.current = null
    set_file_name(false)
    onCancel();
  };

  const selectFile = () => {
    file.current.click()
  }

  return (
    <Modal
      title={name}
      open={visible}
      footer = {null}
      onCancel={handleCancle}
      cancelText={"Close"}
      {...props}
      destroyOnClose
      confirmLoading={loading}
    >
      <Row justify={"center"}>
        <form method="post" enctype="multipart/form-data" onSubmit={handleFileChange} style={{display: 'flex', placeContent: 'center', flexDirection: 'column'}}>
          <div style={{padding: '20px', border: '2px dashed #fef', maxWidth: '100%', height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={selectFile}>
            <label for={id} style={{fontFamily: 'monospace', fontSize: file_name?.length > 30? 15 : 25, textAlign: 'center'}}>{file_name? file_name : 'Choose CSV file to upload'}</label>
            <input
              type="file"
              name="file"
              accept=".csv"
              style={{display: 'none'}}
              ref={file}
              onChange={onChange}
              disabled={loading}
            />
          </div>
          <div style={{color: 'red', fontFamily: 'cursive', textAlign: 'center'}}>
            <p>Please Note: The CSV file must use pipe (|) as field seperator.</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {showDownload && (
            <Button type="primary" onClick={downloadHandler} disabled={loading}>
              <DownloadOutlined /> Format
            </Button>
            )}
            <button disabled={loading || !file.current} style={{color: loading || !file.current? 'white': 'white', backgroundColor: loading|| !file.current? 'grey': 'blue', border: '.2px solid blue', borderRadius: '8px', padding: '5px 15px', fontFamily: 'cursive'}}>{loading? <Spin /> : 'Submit'}</button>
          </div>
        </form>
      </Row>
    </Modal>
  );
};

export default CustomFileUploadModal;
