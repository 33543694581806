import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom"
import Draggable from 'react-draggable';
import { Select, Button, Modal,  Input, Spin, Row, Col, notification, Drawer, Typography, Space, Empty, Dropdown, Menu, Badge } from 'antd';
import { CheckOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';
import { IoReturnUpBack } from "react-icons/io5";
import { BiBookOpen } from "react-icons/bi";
import CurrencyFormat from 'react-currency-format';
import debounce from 'lodash/debounce';

import { uri, uri_img } from "../../assets/utils/http-request";
import { myRequestObj } from "../../assets/utils/lib";
import { add_approved, add_claim } from '../../stores/Claims';
import { add_queries } from '../../stores/queries';
// import logo from '../../assets/images/logo.png'
import logo from '../../assets/images/claima.png'
import './claims.css';
import { useAuth } from "../../assets/utils/useAuth";
import Helpers from "../../assets/utils/Helpers";
import config from "../../assets/utils/config";

const { TextArea } = Input;

const ClaimsHook = () => {
  const dispatcher = useDispatch();
  const [items, setItems] = useState([]);
  const [response, setResponse] = useState({});
  const [, setDiagnosis] = useState([]);
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [finalDiagenosis, setFinalDiagenosis] = useState([]);
  const [, setValue] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const navigator = useNavigate();
  const [client, setClient] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [approvedCostD, setApprovedCostD] = useState(0)

  // Upload modal controls
  const [rejectionMemo, setRejectionMemo] = useState('');
  const [approvalMemo, setApprovalMemo] = useState('');
  const [, setVisiblePaid] = useState(false);
  const [visibleMedicalComplaints, setVisibleMedicalComplaints] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleApproval, setVisibleApproval] = useState(false);
  const [medicalComplaints, setMedicalComplaints] = useState([]);
  const [ApprovalComplaints, setApprovalComplaints] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef(null);
  const {set, user} = useAuth();
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const interval = useRef();

  const handleCancel = (e) => {
    setVisiblePaid(false);
    setVisibleReject(false);
    setVisibleMedicalComplaints(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  // Submission Controls
  const [, setSubmittedHM] = useState(false)
  const [submittedCFO, setSubmittedCFO] = useState(false)

  useEffect(() => {
    if(user){
      if(![1,3,4,5].includes(Number(user.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/dashboard')
      }
    }else{  
      navigate('/login')
    }
  })

  useEffect(() => {
    if(!params.get('encounter')) navigator('/claims')
    fetchPAData(params.get('encounter')).then(res => {
      setItems(res.items)
      setTotalCost(res.items.reduce((i, j) => {return (Number(j.claim_item_status) === 1)? i + parseFloat(j.approved_cost) : i+0}, 0))
      setApprovedCostD(res.items.reduce((i, j) => {return (Number(j.claim_flag) === 1)? i + parseFloat(j.approved_cost) : i+0}, 0))
      setClient(res.misc);
      dispatcher(add_claim(res.items));
      setResponse(res.claim);
    }).finally(() => updateList());
    return () => clearInterval(interval.current)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(Object.keys(response).length){
      const complaints = response['medical_complaint_hm'].split('|break|').map(el => {
        return el
      })

      const approval = response['claims_comment'].split('|break|').map(el => {
        return el
      })

      setApprovalComplaints(approval);
      setMedicalComplaints(complaints);
    }
  }, [response])

  async function fetchDiagnosisList(username) {
    return myRequestObj(`diagnosisList`).post({search: username})
      .then((response) => response?.data)
      .then((body) =>{
        setDiagnosis(body?.data);
        return body?.data.map((user) => ({
          label: `${user.code} ${user.name}`,
          value: user.id,
        }));
      });
  }

  async function fetchPAData(claimsId) {
    return myRequestObj(`PAListAdminClaim`).post({checkinId: claimsId})
      .then((response) => response?.data)
      .then((body) =>{ 
        const provList = body?.data?.diagnosis?.provisional?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }))
        const finalList = body?.data?.diagnosis?.final?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }))
        setProvDiagenosis(provList)
        setFinalDiagenosis(finalList)
        return body?.data;
      });
  }

  const addDiagnosis = e => {
    setValue(e);
  }

  const send_claim_medical = () => {
    setSending(true);
    setVisibleApproval(false)
    const payload = {checkin_id: params.get('encounter'), claims_status: 1, sender: user.staffId, comment: approvalMemo};
    submitAction(payload).then(data => {
      setSubmittedHM(true);
      if(data.error){
        notification.error({
          message: data.message,
          placement: 'topLeft'
        })
      }else{
        notification.success({
          message: data.message,
          placement: 'topLeft'
        })
        setSending(false);
        navigate('/claims');
      }
    })
  } 

  const revoke_sent_claim = () => {
    const payload = {checkin_id: params.get('encounter'), claims_status: 0, 'items': items.map(i => i.id_), 'purpose': 'revoke'};
    submitAction(payload).then(data => {
      setSubmittedHM(true);
      if(data.error){
        notification.error({
          message: data.message,
          placement: 'topLeft'
        })
      }else{
        notification.success({
          message: data.message,
          placement: 'topLeft'
        })
        window.location.reload();
      }
    })
  } 

  const send_claim_finance = () => {
    setSending(true);
    const payload = {checkin_id: params.get('encounter'), claims_status: 2,  claims_queried: 2};
    submitAction(payload).then(data => {
      setSubmittedCFO(true);
      setSending(false);
      navigator('/claims');
    })
  }

  const reject = () => {
    const payload = {checkin_id: params.get('encounter'), claims_status: 0, 'items': items.map(i => i.id_), comment: rejectionMemo, claims_rejected: 1, sender: user.staffId};
    submitAction(payload).then(data => {
      setVisibleReject(false);
      window.location.reload();
      // setSubmittedPaid(true);
      // setVisiblePaid(false);
    })
  }

  async function submitAction(clean) {
    return myRequestObj(`claimsActions`).post(clean)
    .then((response) => response?.data)
    .then((body) => { 
      return body;
    });
  }

  const updateList = () => {
    if(interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      if(window.location.pathname === '/claims-action'){
        fetchPAData(params.get('encounter')).then(res => {
          setItems(res.items)
          setTotalCost(res.items.reduce((i, j) => {return (Number(j.claim_item_status) === 1)? i + parseFloat(j.approved_cost) : i+0}, 0))
          setApprovedCostD(res.items.reduce((i, j) => {return (Number(j.claim_flag) === 1)? i + parseFloat(j.approved_cost) : i+0}, 0))
          setClient(res.misc);
          dispatcher(add_claim(res.items));
          setResponse(res.claim);
        });
      }
    }, 5000)
  }

  return (
    <>
      <Row  justify="space-between" alignItems="center" style={{marginTop: '1em'}}>
        <Col span={16} >
          <ClientDataHook data = {client} style={{maxWidth: 750}}/>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '10px', marginBottom: '10px'}}>
            <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
              <label className='form-label'>Medical Complaints</label>
              <TextArea rows={3} style={{ width: '100%'}} value={client.medical_complaint} disabled/>
            </div>
            <div className='form-group' style={{ width: '100%', marginTop: '5px', marginBottom: '5px'}} >
              <label className='form-label'>Diagnosis - Final</label>
              <DebounceSelect
                mode="multiple"
                value={finalDiagenosis}
                placeholder="No Final Diagnosis"
                fetchOptions={fetchDiagnosisList}
                onChange={addDiagnosis}
                style={{width: '100%', backgroundColor: 'white', color: 'black', fontWeight: 'bold'}}
                disabled
                maxTagCount={8}
              />
            </div>
          </div>
        </Col>
        <Col span={6} >
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Encounter Code</label>
            <Input rows={3} style={{ width: '100%', backgroundColor: 'white', color: 'black', fontSize: '1.4em', fontWeight: 'bolder'}} value={params.get('encounter')} disabled/>
          </div>
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Encounter Date</label>
            <Input rows={3} style={{ width: '100%', backgroundColor: 'white', color: 'black', fontSize: '1.4em', fontWeight: 'bolder'}} value={(new Date(client.checkin_date)).toLocaleString()} disabled/>
          </div>
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Total Bill</label>
            <span style={{display: 'block', border: '3px solid grey', color: 'blue', fontSize: 32, fontWeight: 'bolder', padding: '5px 20px'}}>
              <CurrencyFormat value={totalCost} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </span>
          </div>
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Total Approved</label>
            <span style={{display: 'block', border: '3px solid grey', color: 'green', fontSize: 32, fontWeight: 'bolder', padding: '5px 20px'}}>
              <CurrencyFormat value={approvedCostD} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </span>
          </div>
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Variance</label>
            <span style={{display: 'block', border: '3px solid grey', color: 'red', fontSize: 32, fontWeight: 'bolder', padding: '5px 20px'}}>
              <CurrencyFormat value={(totalCost - approvedCostD)} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </span>
          </div>
        </Col>
      </Row>
      <hr />
      <div style={{maxHeight: '450px', overflowY: 'auto'}}>
        <label className='form-label'>Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          placeholder={'No Provisional Diagnosis'}
          style={{width: '100%', backgroundColor: 'white', color: 'black', fontWeight: 'bold'}}
          disabled
        >
        </Select>
      </div>
      <div style={{marginTop: 10}}>
        <table className="table align-middle mb-0 bg-white" >
          <thead>
            <tr style={{fontWeight: 'bolder'}}>
              <td>S/N</td>
              <td>Authorization Code</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Filed Cost</td>
              <td>Approved Cost</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return(<AuthorizationRequestDataListHook data = {item} index={index} user={user} claim={response}/>)
            })}
          </tbody>
        </table>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: '30px', justifyContent: 'space-between', gap: '2em'}}>
        <div>
          <Button type="primary" onClick={ () => navigator('/claims') }  icon={<IoReturnUpBack style={{fontSize: '30px', marginRight: 10}}/>}>Close</Button>
        </div>
        <div style={{display: 'flex', gap: '2em'}}>
          {
            ([3].includes(Number(user.role['staffRoleId'])))?
              <div style={{display: 'flex', gap: '2em'}}>
                <Button type="primary" onClick={ () => setVisibleApproval(true) } disabled={(approvedCostD <= 0  || Number(response.claims_status) > 0 || sending)? true: false}>Submit to HM</Button>
                <Button type="primary" onClick={ revoke_sent_claim } disabled={(Number(response.claims_status) === 1)? false: true}>Revoke Submission</Button>
                {
                  (response.medical_complaint_hm !== '')?
                    <Button type="dashed" 
                      danger
                      onClick={() => setVisibleMedicalComplaints(true)}
                    >Memo</Button>:null
                }
              </div>:null
          }
          {
            ([4].includes(Number(user.role['staffRoleId'])))?
              <div style={{display: 'flex', gap: '2em'}}>
                <Button type="primary" onClick={send_claim_finance} disabled={(Number(response.claims_status) < 1 || submittedCFO  || Number(response.claims_status) > 1 || sending)? true: false}>Approve</Button>
                <Button type="primary" danger disabled={(Number(response.claims_status) < 1 || submittedCFO  || Number(response.claims_status) > 1)? true: false} onClick={() => setVisibleReject(true)}>Reject</Button>
                {
                  (response.medical_complaint_hm !== '')?
                    <Button type="dashed" 
                      danger
                      onClick={() => setVisibleMedicalComplaints(true)}
                    >Memo</Button>:null
                }
              </div>:null
          }
        </div>
      </div>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            Claim Approval Memo
          </div>
        }
        visible={visibleApproval}
        onOk={send_claim_medical}
        onCancel={handleCancel}
        destroyOnClose
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        okText = {'Submit'}
      >
        <TextArea rows={5} onChange={e => setApprovalMemo(e.target.value)} />
      </Modal>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            Rejection Memo
          </div>
        }
        visible={visibleReject}
        onOk={reject}
        onCancel={handleCancel}
        destroyOnClose
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        okText = {'Reject'}
      >
        <TextArea rows={5} onChange={e => setRejectionMemo(e.target.value)} />
      </Modal>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            Claims HM Rejection Memo
          </div>
        }
        visible={visibleMedicalComplaints}
        onCancel={handleCancel}
        onOk={handleCancel}
        destroyOnClose
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      > 
          <Typography.Paragraph type="success" code>Approval Memos</Typography.Paragraph>
          {
            ApprovalComplaints.map(element => <p>{element.replace('>>>', '--->')}</p>)
          }
          <Typography.Paragraph type="danger" code>Rejection Memos</Typography.Paragraph>
          {
            medicalComplaints.map(element => <p>{element.replace('>>>', '--->')}</p>)
          }
      </Modal>
    </>
  )
}

const AuthorizationRequestDataListHook = props => {
  const dispatcher = useDispatch();
  const [, setSending] = useState(false);
  const [, setVisibleQuery] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleApproval, setVisibleApproval] = useState(false);
  const [comment, setComment] = useState('');
  const [processed, setProcessed] = useState(false);
  const [processing, setProcessing] = useState(false);
  var claimData = {...props.data};

  // Modal control start
  const [disabled, setDisabled] = useState(false);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const showApprovalComment = () => {
    setVisibleApproval(true);
  };

  const showRejectComment = () => {
    setVisibleReject(true);
  }

  async function submitAction(endpoint) {
    return myRequestObj(`claimItemApprove`).post(claimData)
      .then((response) => response?.data)
      .then(() =>{ 
      });
  }

  const handleOkReject = e => {
    setProcessing(true);
    claimData.claim_flag = 3;
    claimData.claim_comment = comment;
    submitAction();
    dispatcher(add_approved(claimData));
    setSending(true);
    setVisibleReject(false);
    setProcessing(false);
    setProcessed(true);
  }

  const handleOkApprove = e => {
    setProcessing(true);
    claimData.claim_flag = 1;
    claimData.claim_comment = comment;
    submitAction();
    dispatcher(add_approved(claimData));
    setSending(true);
    setVisibleApproval(false);
    setProcessing(false);
    setProcessed(true);
  }

  const handleCancel = (e) => {
    setVisibleApproval(false);
    setVisibleQuery(false);
    setVisibleReject(false)
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };


  return (
    <>
      <tr>
        <td>
          <span><strong>{props.index + 1}</strong></span>
        </td> 
        <td>
          <span><strong>{props.data.auth_code}</strong></span>
        </td> 
        <td>
          <span><strong>{`${props.data.category_name.split(' ')[0]} - ${props.data.item_name}`}</strong></span>
        </td>
        <td>
          <span><strong>{props.data.quantity}</strong></span>
        </td>
        <td>
          <span><strong>{props.data.cost}</strong></span>
        </td>
        <td>
          <span><strong>{props.data.approved_cost}</strong></span>
        </td>
        {
          ([3].includes(Number(props.user.role['staffRoleId'])))?
            <td>
              <span style={{display: 'flex', gap: '5px 5px'}}> 
                <Button type="primary" disabled={(processed || processing || [1,2,3].includes(Number(props.claim.claims_status)) || [1,3].includes(Number(claimData.claim_flag)))} onClick={showApprovalComment}>
                  Approve 
                </Button>
                <Button type="primary" danger disabled={(processed || processing || [1,2,3].includes(Number(props.claim.claims_status)) || [1,3].includes(Number(claimData.claim_flag)))} onClick={showRejectComment}>
                  Reject
                </Button>
                <PAQuery data = {props.data} claim = {props.claim} processed={processed}/>
                {(props.data.doc)? <FileViewerComponent file={props.data.doc} haveFile={Boolean(props.data.doc)} data = {props.data} /> : null }
                {
                  (Number(props.data.claim_flag) && Number(props.data.claim_flag) !== 2)? 
                    (Number(props.data.claim_flag) === 1)? <CheckOutlined  style ={{color: "#4aba6fe3", marginLeft: 10, fontSize: '2em'}}/> :
                      (Number(props.data.claim_flag) === 3)? <CloseOutlined   style ={{color: "red", marginLeft: 10, fontSize: '2em'}}/> : null
                    : null
                } 
              </span>
            </td>:
            <td>
              <span style={{display: 'flex', gap: '5px 5px'}}> 
                {
                  (Number(props.data.claim_flag) && Number(props.data.claim_flag) !== 2)? 
                    (Number(props.data.claim_flag) === 1)? <CheckOutlined  style ={{color: "#4aba6fe3", marginLeft: 10, fontSize: '2em'}}/> :
                      (Number(props.data.claim_flag) === 3)? <CloseOutlined   style ={{color: "red", marginLeft: 10, fontSize: '2em'}}/> : null
                    : null
                } 
              </span>
            </td>
        }
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}} // end
            >
              Approval Memo
            </div>
          }
          open={visibleApproval}
          onOk={handleOkApprove}
          onCancel={handleCancel}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText = {
            (processing)? <Spin size={'small'} />: 'Approve'
          }
        >
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Comment</label>
            <TextArea rows={3} style={{ width: '100%'}} onChange ={(e) => setComment(e.target.value)}/>
          </div>
        </Modal>
        
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}} // end
            >
              Rejection Memo
            </div>
          }
          visible={visibleReject}
          onOk={handleOkReject}
          onCancel={handleCancel}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText = {
            (processing)? <Spin size={'small'} />: 'Reject'
          }
        >
          <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
            <label className='form-label'>Comment</label>
            <TextArea rows={3} style={{ width: '100%'}} onChange ={(e) => setComment(e.target.value)}/>
          </div>
        </Modal>
      </tr>
    </>
  )
}

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

const ClientDataHook = props => {
  const style = {
    ...props.style,
    display: 'flex',
    color: 'black',
    fontSize: '1.3em',
    textAlign: 'left',
    padding: '.2rem',
    borderWidth: '.2em',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, .4)',
    borderRadius: '.5em',
  }

  return(
    <>
      <Row className='card-c' style={style} align='middle'>
        <Col span={4} className='img-round img-medium'>
          <img
            src={props.data.pic_name? `${uri_img}${props.data.pic_name}` : logo}
            alt=""
            style={{width: '120px', height: '120px', display: 'flex', alignSelf: 'center', justifySelf: 'center', margin: '.3em'}}
            className="rounded-circle"
          />
        </Col>
        <Col span={20} className='card-c-details' style={{display: 'flex', flexDirection:'column', alignSelf: 'center', justifySelf: 'center', paddingLeft: '30px'}}>
          <Row className='card-c-datarow'>
            <Col span={10} className='card-c-label'>Patient Full Name:</Col>
            <Col className='card-c-value'> {props.data.surname? props.data.surname.toUpperCase() : ''}, {props.data.first_name? props.data.first_name : ''} {props.data.middle_name? props.data.middle_name : ''}</Col>
          </Row>
          <Row className='card-c-datarow'>
            <Col span={10} className='card-c-label'>Patient Insurance ID:</Col>
            <Col className='card-c-value'> {props.data.insurance_no? props.data.insurance_no : '' }</Col>
          </Row>
          <Row className='card-c-datarow'>
            <Col span={10} className='card-c-label'>Patient Premium Type:</Col>
            <Col className='card-c-value'> {props.data.insurance_package? props.data.insurance_package : ''} | {props.data.premium_type? props.data.premium_type : ''}</Col>
          </Row>
          <Row className='card-c-datarow'>
            <Col span={10} className='card-c-label'>Patient Premium Status:</Col>
            <Col className='card-c-value'> {(props.data.status)? `Active`:'Expired'}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

const PAQuery = ({ data, processed, claim }) => {
  const dispatcher = useDispatch();
  const queries = useSelector(state => state.QueriesStore.messages).filter(i => Number(i.marker) === data.id_ && Number(i.level) === 1)
  const [loading, setLoading] = useState(false);
  const [, setsending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageData, ] = useState({ relation: 'claim_items', marker: data.id_, source: 0, level: 1, facility_type: 0 });
  const [, setFirstLoad] = useState(true);
  const [unreadQueriesCount, setUnreadQueriesCount] = useState(0);
  const [queried, setQueried] = useState(0);
  const [reading, setReading] = useState(false);

  let interval = useRef();
  let interval2 = useRef();

  useEffect(() => {
    const payload = {id_: data.id_, source: 1, level: 1}
    myRequestObj(`queriesCount`).post(payload)
    .then(res => res?.data)
    .then(body => {
      if(!body?.error){
        setUnreadQueriesCount(body?.data[0].queris);
        setQueried(Number(body?.data[1].querid))
      }
    })
    .finally(() => {
      if(interval2.current) clearInterval(interval2.current);
      interval2.current = setInterval(() => {
        if(!reading && window.location.pathname === '/claims-action'){
          myRequestObj(`queriesCount`).post(payload)
          .then(res => res?.data)
          .then(body => {
            if(!body?.error){
              setUnreadQueriesCount(body?.data[0].queris);
              setQueried(Number(body?.data[1].querid))
            }
          })
        }
      }, 2000)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);

    if(visible){
      localStorage.setItem(config.key.q_reading, 1);
    }

    fetchQueries(visible).then(res => {
      setLoading(false);
      updateQueries();
    })

    setReading(true);
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queriesToUpdate = queries.filter(j => !Number(j.status) && Number(j.source))
    .map(i => {
      return {
        id: i.id
      }
    })

    if(visible && queriesToUpdate.length){
      myRequestObj(`update_queries`).post(queriesToUpdate)
      .then(res => res?.data)
      .finally(setFirstLoad(false));
    }
  }, [visible, queries])

  const handleOk = () => {
    setsending(true);
    const payload = {content: messageText, ...messageData}
    myRequestObj(`query`).post(payload)
    .then(res => res?.data)
    .then(body => {
      if(!body?.error){
        dispatcher(add_queries(body?.data));
      }
      setsending(false);
      setMessageText('');
    })
  };

  const handleCancel = () => {
    localStorage.setItem(config.key.q_reading, 0);
    clearInterval(interval.current);
    setVisible(false);
    setReading(false);
  };

  const contoller = () => {
    setVisible(true);
  }

  const composeMessage = e => {
    const mess = e.target.value;
    setMessageText(mess)
  }

  const fetchQueries = async (x) => {
    if(!x) return;
    return myRequestObj(`queries`).post(messageData)
    .then(res => res?.data)
    .then(body => {
      if(!body?.error && body?.data?.length){
        dispatcher(add_queries(body.data));
      }
    })
  }

  const updateQueries = () => {
    interval.current = setInterval(() => fetchQueries(visible), 5000)
  }

  return (
    <>
      <Badge count={Number(unreadQueriesCount) || null} >
        <MessageOutlined 
        style={{fontSize: '2em', fontWeight: 'bolder', color: (([1,2,3].includes(Number(claim.claims_status)) && queried) || ([1,3].includes(Number(data.claim_flag)) && queried) || (Number(data.claim_flag) < 1) || (Number(data.claim_flag) === 2) || (processed && queried))? '#ff7875' : 'grey'}}  
        onClick={() => (([1,2,3].includes(Number(claim.claims_status)) && queried) || ([1,3].includes(Number(data.claim_flag)) && queried) || (Number(data.claim_flag) < 1) || (Number(data.claim_flag) === 2) || (processed && queried))? contoller() : null} 
        disabled ={(([1,2,3].includes(Number(claim.claims_status)) && queried) || ([1,3].includes(Number(data.claim_flag)) && queried) || (Number(data.claim_flag) < 1) || (Number(data.claim_flag) === 2) || (processed && queried))? false : true } title="Query"/>
      </Badge>
      <Drawer
        open={visible}
        title={<>
          <Typography.Title level={5} style={{margin: '0 auto'}}>
            Query: {data.checkin_id}
          </Typography.Title>
        </>}
        onClose={handleCancel}
        footer={[
          <Row justify="space-between" align="bottom">
            <Col span={20}>
              <TextArea
                disabled={(([1,2,3].includes(Number(claim.claims_status)) && queried) || ([1,3].includes(Number(data.claim_flag)) && queried)) || (processed && queried)}
                rows={2} value={messageText} placeholder='Write Full Description of the Query....' onChange={composeMessage} id={'message'}/>
            </Col>
            <Col>
              <Button
                key="back" onClick={handleOk} style={{ color: 'skyblue', fontSize: '1em' }} 
                disabled={(([1,2,3].includes(Number(claim.claims_status)) && queried) || ([1,3].includes(Number(data.claim_flag)) && queried)) || (processed && queried) || !messageText.length}
              > Send </Button>
            </Col>
          </Row>
        ]}
        width={500}
      >
        <Space gutter={[8, 16]} direction={'vertical'} id='chatContainer' style={{width: '100%'}}>
          {(loading)?
            <Col>
              <Spin size="large" />
            </Col>
            :(!queries.length)?
              <Empty description={<span>
                No Queries
              </span>} />:
              <>
                {queries.sort((a, b) => Date.parse(a.date) - Date.parse(b.date)).map((q, i) => 
                  <Row id={`query_${i}`} 
                    justify={(!Number(q.source))? 'start' : 'end'}
                  >
                    <Col span={16}
                      style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        lineHeight: '18px',
                        width: 'fit-content', 
                        color: `${!Number(q.source)? 'green': 'red'}`, 
                        border: `${!Number(q.source)? '1px solid green': '1px solid red'}`, 
                        borderRadius: '10px' , padding: '10px'
                      }}
                    >
                      <p>{q.content}</p>
                      <span style={{textAlign: 'right', justifySelf: 'flex-end'}}>{(new Date(q.date)).toLocaleString()}</span>
                    </Col>
                  </Row>
                )}
              </>
          }
        </Space>          
      </Drawer>
    </>
  );
};


const FileViewerComponent = ({ data, haveFile, file }) => {
  const dispatcher = useDispatch();
  const [, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageData, ] = useState({ relation: 'claim_items', marker: data.id_, source: 0, level: 1 });
  const [fileToView, setfileToView] = useState('');
  const [fileLabel, setFileLabel] = useState('');
  const files = file.split('|')

  let interval = useRef();

  useEffect(() => {
    setLoading(true);
    fetchQueries(visible).then(res => {
      setLoading(false);
      updateQueries();
    })
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    clearInterval(interval.current);
    setVisible(false);
  };

  const fetchQueries = async (x) => {
    if(!x) return;
    return myRequestObj(`queries`).post(messageData)
    .then(res => res?.data)
    .then(body => {
      if(!body.error && body.data.length){
        dispatcher(add_queries(body.data));
      }
    })
  }

  const updateQueries = () => {
    interval.current = setInterval(() => fetchQueries(visible), 5000)
  }

  const menuHandler = e => {
    setfileToView(e.key);
    setFileLabel(e.label)
    setVisible(true);
  }

  return (
    <>
      {
        haveFile? 
        <Badge count={files.length}>
          <Dropdown overlay={<Menu items={files.map((uuri) => {return {
            label: `Document ${uuri.split('_')[1].split('.')[0]}`,
            key: uuri
          }})} onClick={menuHandler} />} >
            <Button type="dashed" danger style={{display: 'flex'}}>
              <BiBookOpen style={{fontSize: '2em', alignSelf: 'center'}}/>  
            </Button> 
          </Dropdown> 
        </Badge>: null
      }
      <Drawer
        open={visible}
        title={<>
          <Typography.Title level={5} style={{margin: '0 auto'}}>
            Files for: {data.auth_code}
          </Typography.Title>
        </>}
        onClose={handleCancel}
        width={800}
      >
        <iframe src={`${uri+fileToView}`} height={'100%'} width={'100%'} title={`Document ${fileLabel}`} />
      </Drawer>
    </>
  );
};

export default ClaimsHook;